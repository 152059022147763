import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { UnAuthRedirect } from '@uz/unitz-tool-components/AppRedirect';
import { ctx } from '@vl/redata';
import _ from 'lodash';
import StudentBrief from '@uz/unitz-tool-components/StudentBrief';
import MemberStatistical from '@uz/unitz-tool-components/MemberStatistical';
import TeacherCourseAttendanceTable from '@uz/unitz-tool-components/TeacherCourseAttendanceTable';
import FormSendEmailReportModal from '@uz/unitz-tool-components/FormSendEmailReportModal';
import Link from '@uz/unitz-components-web/Link';
import Button from '@uz/unitz-components-web/Button';
import { renderPropsComposer } from '@vl/mod-utils/PropsComposer';
import { Row, Col } from 'antd';
import gstyles from '@vl/gstyles';

const Index = () => {
  return (
    <DIV className="pageContainer">
      <UnAuthRedirect to={ctx.apply('authModel.generateLoginRedirectUrl')}>
        <div className="m-4">
          <div className="p-6 bg-white500">
            <div className="text-2xl font-semibold text-main">
              {ctx.apply('i18n.t', 'ZoomToolStudent.report_title')}
            </div>
            <div className="p-8">
              <StudentBrief />
            </div>
            <MemberStatistical />
            {renderPropsComposer(
              {
                matcher: (props) => !_.get(props, 'items.length'),
                render: () => (
                  <Row justify="center" className="py-16">
                    <Col span={12}>
                      <div className="flex flex-col items-center justify-center">
                        <div className="mb-4">
                          {gstyles.icons({
                            name: 'courseEmpty',
                            size: 152,
                          })}
                        </div>
                        <div className="mb-6 text-center text-2xl font-semibold text-sub">
                          {ctx.apply('i18n.t', 'ZoomToolMember.empty_course_state_title')}
                        </div>
                      </div>
                    </Col>
                  </Row>
                ),
              },
              {
                matcher: (props) => !!_.get(props, 'items.length'),
                render: () => <TeacherCourseAttendanceTable />,
              },
              () => null
            )(ctx.get('paginationModel'))}
            <Row className="mt-8" justify="end" align="middle">
              <Col span={18}>
                <Row gutter={24}>
                  <Col span={8}>
                    <Link to={ctx.apply('routeStore.toUrl', 'toolAccountStudents')}>
                      <Button style={{ borderColor: gstyles.colors.border }} type="outline" name="ink" block>
                        {ctx.apply('i18n.t', 'ZoomToolMember.Action.back')}
                      </Button>
                    </Link>
                  </Col>
                  <Col span={8}>
                    <Button type="primary" name="primary" block>
                      {ctx.apply('i18n.t', 'ZoomToolMember.Action.export_to_pdf')}
                    </Button>
                  </Col>
                  <Col span={8}>
                    <Button type="primary" onClick={ctx.get('modalModel.showModal')} block>
                      {ctx.apply('i18n.t', 'ZoomToolMember.Action.send_email')}
                    </Button>
                    <FormSendEmailReportModal />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </UnAuthRedirect>
    </DIV>
  );
};

export default displayName(Index);
